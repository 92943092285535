import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Flip } from "gsap/Flip";
import ScrollSmoother from "./libs/gsap/ScrollSmoother.3.11.5.min.js";

window.addEventListener('DOMContentLoaded', () => loadHandler())


function loadHandler() {
    gsap.registerPlugin(Flip, ScrollTrigger, ScrollSmoother)

    scrollSmootherInit()
    preloadInit()
    // animationInit()

}


function scrollSmootherInit() {
    if (ScrollTrigger.isTouch !== 1) {
        window.scrollY = 0
        window.pageYOffset = 0
        ScrollSmoother.create({
            wrapper: '.smooth-wrapper',
            content: '.smooth-content',
            smooth: 3,
            effects: true,
        })
    }


}

function preloadInit() {
    setTimeout(() => {
        document.body.classList.add('loaded')
        animationInit()
    }, 250)
}

function animationInit() {

    gsap
        .timeline({
            delay: ScrollTrigger.isTouch !== 1 ? 1 : 0,
            defaults: {
                duration: 1,
            }
        })
        .from(
            '.started .started-img',
            { xPercent: -67 }
        )
        .from(
            '.header',
            { y: -100, opacity: 0 },
            ScrollTrigger.isTouch !== 1 ? '-=0.75' : '-=1'
        )
        .from(
            '.started-title',
            { x: -200, opacity: 0 },
            '-=0.75'
        )
        .from(
            '.started-subtitle',
            { x: -200, opacity: 0 },
            '-=0.75'
        )
        .from(
            '.started-btn',
            { x: -200, opacity: 0 },
            '-=0.75'
        )

    gsap.from(
        '.book-spoot-1',
        {
            yPercent: 100,
            scrollTrigger: {
                trigger: '.book',
                start: 'top bottom',
                end: '30% top',
                scrub: 2
            }
        }
    )

    gsap.from(
        '.book-spoot-2',
        {
            yPercent: 100,
            scrollTrigger: {
                trigger: '.book',
                start: '30% bottom',
                end: '60% top',
                scrub: 2
            }
        }
    )

    gsap.fromTo(
        '.book-clouds',
        {
            y: 300,
        },
        {
            y: -300,
            scrollTrigger: {
                trigger: '.book',
                start: '50% bottom',
                end: '70% top',
                scrub: 2,
            }
        }
    )

    gsap.fromTo(
        '.reviews-sheet',
        {
            y: 300,
        },
        {
            y: -100,
            scrollTrigger: {
                trigger: '.reviews-items',
                start: 'top bottom',
                end: '70% top',
                scrub: 2,

            }
        }
    )

    gsap.fromTo(
        '.reviews-item-spoot',
        {
            y: 60,
        },
        {
            y: -20,
            scrollTrigger: {
                trigger: '.reviews-items',
                start: 'top bottom',
                end: '70% top',
                scrub: 2,
            }
        }
    )
    gsap.from(
        '.footer-waves',
        {
            y: 300,
            scrollTrigger: {
                trigger: '.footer',
                start: 'top bottom',
                end: 'bottom bottom',
                scrub: 2,
            }
        }
    )

}